import {Box, Grid, Typography} from "@mui/material";

export const HomeTextBlock = ({section, direction}) => {

    return (
        <Box sx={{p:0.5 , border:'1px solid #eaeaea', width:'100%', borderRadius:'5px', marginBottom:'15px'}}>
            <Grid container direction={{xs:'column', md:direction}} spacing={{xs:1, md:4}} alignItems={'center'}>
                {section.image && <Grid item xs={12} md={3} sx={{flexGrow: 1}}>
                    <img style={{width: '100%'}}
                         src={require(`../../assets/images/${section.image}.png`)}/>
                </Grid>}
                <Grid item xs={12} md={9} sx={{flexGrow: 1}}>

                    <Box sx={{py:2, borderLeft:'3px solid #e3007d'}}>
                        <Typography variant={'h6'} color={'#e53323'} fontWeight={'bold'} textTransform={'uppercase'} style={{marginLeft:'1%'}}>
                            {section.title}
                        </Typography>
                    </Box>
                    <Typography variant={'body1'}>
                        {section.description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}