import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD0a2UMBqGjfVetYqJ-au06jo35-C-PkY4",
    authDomain: "wedays-edenred-2024.firebaseapp.com",
    projectId: "wedays-edenred-2024",
    storageBucket: "wedays-edenred-2024.appspot.com",
    messagingSenderId: "821574580059",
    appId: "1:821574580059:web:c94a64300fa28bd0cc8c7e"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()

const firestore = firebase.firestore()

export {firestore, firebase, auth}
