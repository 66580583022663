import React from 'react';
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";

export const CardProgramma = ({day, i}) => {
    return (
        <Card sx={{width: '100%', height: '100%', border:'1px solid #dedede'}}>
            <CardMedia>
                <img style={{width: '100%', // Set a fixed width
                    height: '350px', borderRadius:'50%', margin:'auto'}} src={require(`../../assets/images/${day.image}`)}/>
            </CardMedia>
            <CardContent>
                <Box sx={{borderTop: '3px solid #e62f22', px:2, py:1, borderTopWidth:'5px'}}>
                    <Typography color='#e62f22' variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
                        {i + 1}° giorno
                    </Typography>
                    <Typography textAlign={'center'}>
                        {day.day}
                    </Typography>
                </Box>
                <Box sx={{pt:3}}>
                    {day.sections.map((section, index) =>
                        <Box key={index}>
                            {i<2 && <Typography variant={'body1'} color={'#e3007d'}>
                                <br/>
                                {section.title} <span style={{color: 'black'}}>- </span>
                                {section.activities.map((item, index) =>

                                    <span style={{color: 'black'}}>
                                        {item} <br/>
                                    </span>

                                )}
                            </Typography>}
                            {i===2 && <Typography variant={'body1'} color={'#e3007d'}>
                                <br/>
                                {section.title} <span style={{color: 'black'}}></span>
                                {section.activities.map((item, index) =>

                                    <span style={{color: 'black'}}>
                                        {item} <br/>
                                    </span>
                                )}
                            </Typography>}
                            {i===2 && <Typography variant={'body1'} color={'#000000'}>
                                {section.specialtitle}
                                {section?.specialactivities?.map((item, index) =>

                                    <span style={{color: '#e31e13'}}>
                                        {item} <br/>
                                    </span>
                                )}
                            </Typography>}
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}
