import React from "react";
import {Website} from "../layouts/Website";
import {Box, Container, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";
import {contactsMap} from "../data/contactsMap";

export const Contacts = () => {
    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6}}>
                <PageTitle title={'contatti'} image={'icon-contacts'}/>
                <Typography sx={{ py:1, color:'#E51576'}} fontWeight={'bold'} variant={'h6'}>
                    I seguenti contatti sono attivi esclusivamente dal 26 al 28 settembre
                </Typography>
                <Box>
                    {
                        contactsMap.map((contact, index) =>
                            <Box key={index} sx={{py:1}}>
                                <Typography variant={'h6'} fontWeight={'bold'} color={'#E51576'}>
                                    {contact.title}
                                </Typography>
                                {contact.description && <Typography>
                                    {contact.description}
                                </Typography>}
                                {
                                    contact.list &&
                                    <ul style={{marginBlock:0}}>
                                        {contact.list.map((item, index) =>
                                            <li key={index}>
                                                <Typography>
                                                    {item}
                                                </Typography>
                                            </li>
                                        )}
                                    </ul>
                                }
                            </Box>
                        )
                    }
                </Box>
            </Container>
        </Website>
    )
}
