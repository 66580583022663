export const contactsMap = [
    {
        title: 'SEGRETERIA ORGANIZZATIVA',
        list: [
            <>
                tel: 334 666 3953
            </>,
            <>
                mail: segreteria@we-days2024.it
            </>
        ]
    },
    {
        title: 'TOUR LEADER (stazione Milano Centrale)',
        list: [
            <>
                Camilla Del Cerri (cell. 349 5125 100)
            </>,
            <>
                Monia Ciurcovich (cell. 344 0637 985)
            </>,
            <>
                Ilaria Comiotto (cell. 347 3547 438)
            </>
        ]
    },
    {
        title: 'TOUR LEADER (stazione Roma Termini)',
        list: [
            <>
                Alessandra Paris (cell. 338 9840 333)
            </>
        ]
    },
    {
        title: 'RESPONSABILE TRASFERIMENTI',
        list: [
            <>
                Lorenzo Alessandrino (cell. 328 972 7034)
            </>
        ]
    },
    {
        title: 'STAFF IN LOCO',
        list: [
            <>
                <b>1. Hilton Sorrento Palace 4*</b>
                <br/>
                Alessandra Paris (cell. 338 9840 333)
                <br/>
                Barbara Boccini (cell. 339 2869 979)
                <br/>
                Chiara Babini (cell. 347 5002 100)
            </>,
            <>
                <b>2. Grand Hotel Europa Palace 4*</b>
                <br/>
                Camilla Del Cerri (cell. 349 5125 100)
            </>,
            <>
                <b>3. Art Hotel Gran Paradiso 4*</b>
                <br/>
                Ilaria Comiotto (cell. 347 3547 438)
                <br/>
                Monia Ciurcovich (cell. 344 0637 985)
            </>
        ]
    }
]
