import headerStyle from "./Header.module.css";

import edenredLogo from "../assets/images/edenred-logo.svg";
import weareoneLogo from "../assets/images/weareone-logo.svg";
function Header() {
    return(
        <div className={headerStyle.mainContainer}>
            <img src={edenredLogo} alt={'edenred logo'} className={headerStyle.edenredLogo}/>
            <img src={weareoneLogo} alt={'edenred logo'} className={headerStyle.weareoneLogo}/>
            <h1 className={headerStyle.title}>
                UN NUOVO VIAGGIO <br/> DA VIVERE <span style={{fontWeight:"bold"}}>INSIEME </span>
            </h1>
            <h1 className={headerStyle.subtitle}>
                <span className={headerStyle.gradientText}>26 - 28</span> SETTEMBRE 2024
            </h1>
        </div>
    )
}

export default Header;