import {Website} from "../layouts/Website";

import {Box, Container, Typography} from "@mui/material";
import {homeMap} from "../data/homeMap";
import {HomeTextBlock} from "../components/shared/HomeTextBlock";

const HomePage = () => {
    return(
        <Website>
            <Box sx={{width:'100%'}}>
                <img style={{width:'100%'}} src={require('../assets/images/homepage-img-main.jpg')} alt={'home-header'}/>
                <Box sx={{width:'100%', backgroundImage: 'linear-gradient(to right, #e31e13 20%, #e3007d 80%)', marginTop: '-10px'}}>
                    <Typography sx={{p: 3}} textAlign={'center'} variant={'h4'} color={'#ffffff'} fontWeight={'bold'} textTransform={'uppercase'} style={{marginLeft:'1%'}}>
                        curiosità
                    </Typography>
                </Box>
            </Box>
            <Container sx={{py:2}}>
                {
                    homeMap.map((part, index) =>
                        <Box key={part.title} sx={{pb:2}} key={part.title}>
                            {
                                part.sections.map((section, index) =>
                                    <HomeTextBlock section={section} key={section.title} direction={'row'}/>
                                )
                            }
                        </Box>
                    )
                }
            </Container>
        </Website>
    )
}

export default HomePage;