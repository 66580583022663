import homeStyle from './Home.module.css';
import Header from "../components/Header";
import {Alert, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField} from "@mui/material";
import {
    CustomButton,
    CustomCheckbox, CustomCheckBoxIcon,
    CustomCheckBoxOutlineBlankIcon,
    CustomFormControlLabel, CustomRadio,
    CustomTextField
} from "../assets/mui-styles/styles.js";
import {useEffect, useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";

import bkgUp from "../assets/images/bkg-up.svg";
import bkgDown from "../assets/images/bkg-down.svg";
import {useParams} from "react-router-dom";

import {API_URL} from "../config";
import Footer from "../components/Footer";
import axios from "axios";


const phone_regex = /^\+?([0-9]{1,3})?[-. ]?(\([0-9]{1,3}\)|[0-9]{1,3})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})$/;


function Home() {
    const {id} = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState(null);

    const [formErrors, setFormErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [nextStep, setNextStep] = useState(false);
    const [noPartecipazione, setNoPartecipazione] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_URL}profile/${id}`);
                setData(response.data); // Axios incapsula i dati risposta in `response.data`
                setFormValues({
                    ...formValues,
                    nome: response.data.nome,
                    cognome: response.data.cognome,
                    email: response.data.email
                });
            } catch (err) {
                setError1(err.message);
            }
            setLoading(false);
        };

        fetchData();
    }, [id]);

    // Stati per i campi del form
    const [formValues, setFormValues] = useState({
        partecipazione: '',
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        cittaProvenienza: '',
        sesso: '',
        nomeDocumento: '',
        cognomeDocumento: '',
        luogoNascita: '',
        dataNascita: null,
        tipoDocumento: '',
        numeroDocumento: '',
        dataEmissione: null,
        dataScadenza: null,
        // allergie: '',
        privacyAccepted: false,
        // imageConsent: false,
    });

    function isNotFutureDate(inputDate) {
        const today = new Date();
        const input = new Date(inputDate);

        // Imposta l'orario di entrambe le date a mezzanotte per un confronto accurato
        today.setHours(0, 0, 0, 0);
        input.setHours(0, 0, 0, 0);

        // Confronta le due date
        return input <= today;
    }

    const submitPartecipazione = async (event) => {
        setIsLoading(true);

        if(formValues.partecipazione==='si') {
            setError1(false);
            setNextStep(true);
        } else {
            // TODO: Chiama api mandando partecipazione = no;
            try {
                const response = await axios.post(`${API_URL}register/${id}`, formValues);
                setNoPartecipazione(true);
                setError1(null);
            } catch (err) {
                setError1(err.response.data.error);
            }
        }
        setIsLoading(false);
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleDateChange = (fieldName, newValue) => {
        setFormValues(prevState => ({
            ...prevState,
            [fieldName]: newValue,
        }));
    };

    const validate = (values) => {
        const errors = {};

        if(!phone_regex.test(values.telefono)) errors.telefono = "Numero non valido";
        if (!values.telefono) errors.telefono = "Campo obbligatorio";

        if (!values.cittaProvenienza) errors.cittaProvenienza = "Campo obbligatorio";
        if (!values.sesso) errors.sesso = "Campo obbligatorio";
        if (!values.nomeDocumento) errors.nomeDocumento = "Campo obbligatorio";
        if (!values.cognomeDocumento) errors.cognomeDocumento = "Campo obbligatorio";
        if (!values.luogoNascita) errors.luogoNascita = "Campo obbligatorio";

        if(!isNotFutureDate(values.dataNascita)) errors.dataNascita = "La data non può essere nel futuro";
        if (!values.dataNascita) errors.dataNascita = "Campo obbligatorio";

        if (!values.tipoDocumento) errors.tipoDocumento = "Campo obbligatorio";
        if (!values.numeroDocumento) errors.numeroDocumento = "Campo obbligatorio";

        if(!isNotFutureDate(values.dataEmissione)) errors.dataEmissione = "La data non può essere nel futuro";
        if (!values.dataEmissione) errors.dataEmissione = "Campo obbligatorio";

        if (!values.dataScadenza) errors.dataScadenza = "Campo obbligatorio";
        if (!values.privacyAccepted) errors.privacyAccepted = "Campo obbligatorio";
        //if (!values.imageConsent) errors.imageConsent = "Campo obbligatorio";
        // console.info(errors);
        // Aggiungi altre regole di validazione qui
        return errors;
    };

    const handleSubmit  = async (event) => {
        event.preventDefault();

        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            const formattedDataNascita = formValues.dataNascita.format('DD/MM/YYYY');
            const formattedDataEmissione = formValues.dataEmissione.format('DD/MM/YYYY');
            const formattedDataScadenza = formValues.dataScadenza.format('DD/MM/YYYY');

            const formData = {
                ...formValues,
                dataNascita: formattedDataNascita,
                dataEmissione: formattedDataEmissione,
                dataScadenza: formattedDataScadenza
            };

            //console.info(formData);

            // TODO: Gestire invio dati form
            setIsLoading(true);
            try {
                const response = await axios.post(`${API_URL}register/${id}`, formData);
                setError1(null);
                setSuccess(true);
            } catch (err) {
                setError1(err.response.data.error);
            }
            // Qui potresti resettare il form o gestire la transizione di stato post invio
            setIsLoading(false);
        }
    }

    {/*
        return (
            <div style={{width: '100vw', position: 'relative'}}>
                <Header></Header>
                <div className={homeStyle.mainContainer}>
                    <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                    <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                    <div className={homeStyle.formContainer}>
                        <div style={{textAlign: "center", width: "65%"}}>
                            <h2>Le registrazioni sono chiuse. </h2>
                            <p>
                                Per qualsiasi esigenza puoi contattare la Segreteria Organizzativa all’indirizzo mail
                                <br/>
                                <a
                                    href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a>
                            </p>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    */}

    if (!data) {
        if (loading) {
            return (
                <div style={{
                    width: "100vw",
                    height: "100vh",
                    alignContent: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <CircularProgress></CircularProgress>
                </div>
            )
        }
        return (
            <div style={{
                width: "100vw",
                height: "100vh",
                alignContent: 'center',
                justifyContent:'center', textAlign:'center'}}>
                <h1>Utente non abilitato</h1>
            </div>
        )
    }

    return(
        <div style={{width: '100vw', position:'relative'}}>
            <Header></Header>
            <div className={homeStyle.mainContainer}>
                <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                {error1 &&
                    <Alert style={{position:"fixed", left:'2vw' ,bottom:'2vh', color:'white', zIndex:'1000'}} severity="error" variant="filled">
                        {error1}
                    </Alert>
                }
                {!nextStep &&
                    <div className={homeStyle.formContainer}>
                        { !noPartecipazione &&
                            <>
                                <h1 className={homeStyle.formTitle}>
                            <span
                                style={{fontWeight: '500', color: '#E51576'}}
                            >
                                PARTECIPERAI ALL'EVENTO?
                            </span>
                                </h1>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={'partecipazione'}
                                    value={formValues.partecipazione} // Imposta il valore selezionato sul RadioGroup
                                    onChange={handleChange} // Gestisci il cambiamento di selezione
                                >
                                    <CustomFormControlLabel  value="si" control={<CustomRadio size="large"/>}
                                                            label="SI"/>
                                    <CustomFormControlLabel sx={{marginLeft:"10vw"}} value="no" control={<CustomRadio size="large"/>}
                                                            label="NO"/>
                                </RadioGroup>
                                {formValues.partecipazione === 'no' &&
                                    <h3 className={homeStyle.formSubtitle1}>
                                        *In caso di mancata adesione all’evento, essendo i giorni del 26 e 27 settembre
                                        lavorativi, ricordiamo che sarà possibile lavorare sia in smart working che in
                                        ufficio
                                        presso la propria sede territoriale. Eventuali giorni di ferie dovranno come sempre
                                        essere preventivamente concordati e autorizzati dal proprio responsabile.
                                    </h3>
                                }
                                <div className={homeStyle.buttonContainer}>
                                    {isLoading ? (
                                        <CircularProgress sx={{color: '#f12218'}}/>
                                    ) : (
                                        <CustomButton
                                            onClick={submitPartecipazione}
                                            disabled={!formValues.partecipazione}
                                        >
                                            INVIA
                                        </CustomButton>
                                    )}
                                </div>
                            </>
                        }

                        {noPartecipazione &&
                            <p style={{textAlign: "center"}}>
                                Ci dispiace che non parteciperai all’evento!
                                <br/><br/>
                                Per qualsiasi esigenza puoi contattarci all’indirizzo
                                <br/>
                                <a href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a>
                                <br/><br/>
                                La Segreteria Organizzativa
                            </p>
                        }

                    </div>
                }
                {nextStep &&
                    <div className={homeStyle.formContainer}>
                        {success &&
                            <div style={{textAlign:"center", width:"65%"}}>
                                <h2>La tua risposta è stata registrata con successo!</h2>
                                <p>A breve riceverai un’e-mail automatica con l’esito della tua registrazione <br/> (Se non dovessi averla ricevuta, ricordati di controllare nella cartella SPAM). </p>
                                <p>Per qualsiasi necessità non esitare a contattare la Segreteria Organizzativa
                                    all’indirizzo <br/>
                                    <a
                                        href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a></p>
                            </div>
                        }
                        {!success &&
                            <>
                                <h1 className={homeStyle.formTitle}>
                                <span
                                    style={{fontWeight: '500', color: '#E51576'}}
                                >
                                     FORM DI REGISTRAZIONE
                                </span>
                                </h1>
                                <h3 className={homeStyle.formSubtitle}>
                                    Ti invitiamo a compilare tutti i dati richiesti per completare la tua iscrizione
                                </h3>
                                {/* FORM DI REGISTRAZIONE */}
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} justifyContent="center"
                                          sx={{width: '95%', marginLeft: 0}}
                                          alignItems="center">
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="Nome"
                                                name={'nome'}
                                                value={formValues.nome}
                                                helperText={" "}
                                                onChange={handleChange}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="Cognome"
                                                name={'cognome'}
                                                value={formValues.cognome}
                                                helperText={" "}
                                                onChange={handleChange}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="E-mail"
                                                name={'email'}
                                                value={formValues.email}
                                                helperText={" "}
                                                onChange={handleChange}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="Cellulare"
                                                name={'telefono'}
                                                value={formValues.telefono}
                                                error={formErrors.telefono}
                                                helperText={formErrors.telefono || " "} // Invisible placeholder
                                                onChange={handleChange}
                                                fullWidth/>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection}>INDICA LA TUA CITTÀ DI PROVENIENZA
                                                (INTESA
                                                COME LUOGO DI PARTENZA)
                                            </h4>
                                        </Grid>

                                        <Grid item xs={12} md={10}>
                                            <CustomTextField
                                                name={'cittaProvenienza'}
                                                label="Città di provenienza"
                                                value={formValues.cittaProvenienza}
                                                error={formErrors.cittaProvenienza}
                                                helperText={formErrors.cittaProvenienza || " "}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection}>
                                                INDICA I DATI COMPLETI
                                            </h4>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name={'sesso'}
                                                value={formValues.sesso} // Imposta il valore selezionato sul RadioGroup
                                                onChange={handleChange} // Gestisci il cambiamento di selezione
                                            >
                                                <CustomFormControlLabel value="sig"
                                                                        control={<CustomRadio size="large"/>}
                                                                        label="SIG"/>
                                                <CustomFormControlLabel value="sig.ra"
                                                                        control={<CustomRadio size="large"/>}
                                                                        label="SIG.RA"/>
                                            </RadioGroup>
                                            <p className={homeStyle.errorMessage}>{formErrors.sesso}</p>
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="Nome(come da documento)"
                                                name={'nomeDocumento'}
                                                value={formValues.nomeDocumento}
                                                error={formErrors.nomeDocumento}
                                                helperText={formErrors.nomeDocumento || " "}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <CustomTextField
                                                label="Cognome(come da documento)"
                                                name={'cognomeDocumento'}
                                                value={formValues.cognomeDocumento}
                                                error={formErrors.cognomeDocumento}
                                                helperText={formErrors.cognomeDocumento || " "}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <CustomTextField
                                                label="Luogo di nascita"
                                                name={'luogoNascita'}
                                                value={formValues.luogoNascita}
                                                error={formErrors.luogoNascita}
                                                helperText={formErrors.luogoNascita || " "}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection}>
                                                DATA DI NASCITA
                                            </h4>
                                            <DatePicker
                                                value={formValues.dataNascita}
                                                name={'dataNascita'}
                                                format={'DD/MM/YYYY'}
                                                onChange={(newValue) => handleDateChange('dataNascita', newValue)}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: 'Edenred',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: formErrors.dataNascita ? '#d32f2f' : 'grey',
                                                            borderWidth: '2px',
                                                            borderRadius: '20px',
                                                            fontFamily: 'Edenred, sans-serif'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Boolean(formErrors.dataNascita) ? '#d32f2f' : 'primary.main', // change to theme's primary color when focused if no error
                                                        }
                                                    },
                                                }}
                                            />
                                            <p className={homeStyle.errorMessage}>{formErrors.dataNascita}</p>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection}>
                                                TIPOLOGIA DOCUMENTO
                                            </h4>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name={'tipoDocumento'}
                                                value={formValues.tipoDocumento} // Imposta il valore selezionato sul RadioGroup
                                                onChange={handleChange} // Gestisci il cambiamento di selezione
                                            >
                                                <CustomFormControlLabel value="identita"
                                                                        control={<CustomRadio size="large"/>}
                                                                        label="CARTA IDENTITÀ"/>
                                                <CustomFormControlLabel value="passaporto"
                                                                        control={<CustomRadio size="large"/>}
                                                                        label="PASSAPORTO"/>
                                            </RadioGroup>
                                            <p className={homeStyle.errorMessage}>{formErrors.tipoDocumento}</p>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <CustomTextField
                                                label="N. DOCUMENTO"
                                                name={'numeroDocumento'}
                                                value={formValues.numeroDocumento}
                                                error={formErrors.numeroDocumento}
                                                helperText={formErrors.numeroDocumento || " "}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <h4 className={homeStyle.formSection}>
                                                DATA DI EMISSIONE
                                            </h4>
                                            <DatePicker
                                                value={formValues.dataEmissione}
                                                name={'dataEmissione'}
                                                format={'DD/MM/YYYY'}
                                                onChange={(newValue) => handleDateChange('dataEmissione', newValue)}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: 'Edenred',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: formErrors.dataEmissione ? '#d32f2f' : 'grey',
                                                            borderWidth: '2px',
                                                            borderRadius: '20px',
                                                            fontFamily: 'Edenred, sans-serif'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Boolean(formErrors.dataEmissione) ? '#d32f2f' : 'primary.main', // change to theme's primary color when focused if no error
                                                        }
                                                    },
                                                }}
                                            />
                                            <p className={homeStyle.errorMessage}>{formErrors.dataEmissione}</p>
                                        </Grid>
                                        <Grid item xs={6} md={5}>
                                            <h4 className={homeStyle.formSection}>
                                                DATA DI SCADENZA
                                            </h4>
                                            <DatePicker
                                                value={formValues.dataScadenza}
                                                name={'dataScadenza'}
                                                format={'DD/MM/YYYY'}
                                                onChange={(newValue) => handleDateChange('dataScadenza', newValue)}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: 'Edenred',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: formErrors.dataScadenza ? '#d32f2f' : 'grey',
                                                            borderWidth: '2px',
                                                            borderRadius: '20px',
                                                            fontFamily: 'Edenred, sans-serif'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Boolean(formErrors.dataScadenza) ? '#d32f2f' : 'primary.main', // change to theme's primary color when focused if no error
                                                        }
                                                    },
                                                }}
                                            />
                                            <p className={homeStyle.errorMessage}>{formErrors.dataScadenza}</p>
                                        </Grid>

                                        {/*<Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection}>
                                                INDICA EVENTUALI INTOLLERANZE/ALLERGIE ALIMENTARI
                                            </h4>
                                            <CustomTextField
                                                label="Intolleranze/Allergie"
                                                name={'allergie'}
                                                value={formValues.allergie}
                                                onChange={handleChange}
                                                fullWidth
                                            />
                                        </Grid>*/}

                                        <Grid item xs={12} md={10}>
                                            <h4 className={homeStyle.formSection} style={{fontWeight:'normal'}}>
                                                *Ti preghiamo di segnalare eventuali intolleranze/allergie alimentari
                                                all'indirizzo <a
                                                href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a>
                                            </h4>
                                        </Grid>

                                        <Grid item xs={12} md={10}>
                                            <div className={homeStyle.privacyCheck}>
                                                <CustomFormControlLabel
                                                    control={<CustomCheckbox
                                                        name={'privacyAccepted'}
                                                        checked={formValues.privacyAccepted}
                                                        onChange={handleChange}
                                                        icon={<CustomCheckBoxOutlineBlankIcon/>}
                                                        checkedIcon={<CustomCheckBoxIcon/>}
                                                    />}
                                                    label={
                                                        <span className={homeStyle.checkboxLabel}>
                                                            HO LETTO E COMPRESO {' '}
                                                            <a
                                                                href="/Informativa_privacy.pdf"
                                                                target="_blank"
                                                                style={{textDecoration: 'underline', color: 'blue'}}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                L' INFORMATIVA PRIVACY
                                                            </a>*
                                                        </span>
                                                    }
                                                />
                                                <p className={homeStyle.errorMessage}>{formErrors.privacyAccepted}</p>
                                            </div>
                                            {/*<div className={homeStyle.privacyCheck}>
                                                <CustomFormControlLabel
                                                    control={<CustomCheckbox
                                                        name={'imageConsent'}
                                                        checked={formValues.imageConsent}
                                                        onChange={handleChange}
                                                        icon={<CustomCheckBoxOutlineBlankIcon/>}
                                                        checkedIcon={<CustomCheckBoxIcon/>}
                                                    />}
                                                    label={
                                                        <span className={homeStyle.checkboxLabel}>
                                                            ACCONSENTO AL TRATTAMENTO FOTO/VIDEO SECONDO {' '}
                                                            <a
                                                                href="/Informativa_privacy.pdf"
                                                                target="_blank"
                                                                style={{textDecoration: 'underline', color: 'blue'}}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                            PRIVACY POLICY
                                                            </a> FORNITAMI
                                                        </span>
                                                    }
                                                />
                                            </div>*/}
                                        </Grid>
                                    </Grid>

                                    <div className={homeStyle.buttonContainer}>
                                        {isLoading ? (
                                            <CircularProgress sx={{color: '#f12218'}}/>
                                        ) : (
                                            <CustomButton
                                                type="submit"
                                            >
                                                INVIA
                                            </CustomButton>
                                        )}
                                    </div>
                                </form>
                            </>
                        }
                    </div>
                }
            </div>
            {/*TODO: Footer*/}
            <Footer></Footer>
        </div>
    )
}

export default Home;