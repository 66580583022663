import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../config";
import Header from "../components/Header";
import homeStyle from "./Home.module.css";
import bkgUp from "../assets/images/bkg-up.svg";
import bkgDown from "../assets/images/bkg-down.svg";
import {Alert, CircularProgress, Grid, RadioGroup} from "@mui/material";
import {
    CustomButton,
    CustomFormControlLabel,
    CustomRadio,
    CustomTextField
} from "../assets/mui-styles/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import Footer from "../components/Footer";
import dayjs from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

function Document() {
    const {id} = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState(null);

    const [formErrors, setFormErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    // Stati per i campi del form
    const [formValues, setFormValues] = useState({
        nome: '',
        cognome: '',
        email: '',
        nomeDocumento: '',
        cognomeDocumento: '',
        tipoDocumento: '',
        numeroDocumento: '',
        dataEmissione: null,
        dataScadenza: null
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_URL}profile/${id}`);
                setData(response.data); // Axios incapsula i dati risposta in `response.data`
                setFormValues({
                    ...formValues,
                    nome: response.data.nome,
                    cognome: response.data.cognome,
                    email: response.data.email,
                    nomeDocumento: response.data.nomeDocumento,
                    cognomeDocumento: response.data.cognomeDocumento,
                    numeroDocumento: response.data.numeroDocumento,
                    tipoDocumento: response.data.tipoDocumento,
                    dataEmissione: dayjs(convertDate(response.data.dataEmissione)),
                    dataScadenza: dayjs(convertDate(response.data.dataScadenza))
                });
            } catch (err) {
                setError1(err.message);
            }
            setLoading(false);
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleDateChange = (fieldName, newValue) => {
        setFormValues(prevState => ({
            ...prevState,
            [fieldName]: newValue,
        }));
    };

    function isNotFutureDate(inputDate) {
        const today = new Date();
        const input = new Date(inputDate);

        // Imposta l'orario di entrambe le date a mezzanotte per un confronto accurato
        today.setHours(0, 0, 0, 0);
        input.setHours(0, 0, 0, 0);

        // Confronta le due date
        return input <= today;
    }

    const validate = (values) => {
        const errors = {};

        if (!values.nomeDocumento) errors.nomeDocumento = "Campo obbligatorio";
        if (!values.cognomeDocumento) errors.cognomeDocumento = "Campo obbligatorio";

        if (!values.tipoDocumento) errors.tipoDocumento = "Campo obbligatorio";
        if (!values.numeroDocumento) errors.numeroDocumento = "Campo obbligatorio";

        if(!isNotFutureDate(values.dataEmissione)) errors.dataEmissione = "La data non può essere nel futuro";
        if (!values.dataEmissione) errors.dataEmissione = "Campo obbligatorio";

        if (!values.dataScadenza) errors.dataScadenza = "Campo obbligatorio";
        // console.info(errors);
        // Aggiungi altre regole di validazione qui
        return errors;
    };


    const handleSubmit  = async (event) => {
        event.preventDefault();

        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            //const formattedDataNascita = formValues.dataNascita.format('DD/MM/YYYY');
            const formattedDataEmissione = formValues.dataEmissione.format('DD/MM/YYYY');
            const formattedDataScadenza = formValues.dataScadenza.format('DD/MM/YYYY');

            const formData = {
                ...formValues,
                //dataNascita: formattedDataNascita,
                dataEmissione: formattedDataEmissione,
                dataScadenza: formattedDataScadenza
            };

            //console.info(formData);

            // TODO: Gestire invio dati form
            setIsLoading(true);
            try {
                const response = await axios.post(`${API_URL}update-documents/${id}`, formData);
                setError1(null);
                setSuccess(true);
            } catch (err) {
                setError1(err.response.data.error);
            }
            // Qui potresti resettare il form o gestire la transizione di stato post invio
            setIsLoading(false);
        }
    }

    function convertDate(dateStr) {
        // Split the input date string by '/'
        const [day, month, year] = dateStr.split('/');

        // Return the date in YYYY-MM-DD format
        return `${year}-${month}-${day}`;
    }

    if(loading) {
        return (
            <div style={{width: '100vw', position: 'relative'}}>
                <Header></Header>
                <div className={homeStyle.mainContainer}>
                    <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                    <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                    {error1 &&
                        <Alert style={{position: "fixed", left: '2vw', bottom: '2vh', color: 'white', zIndex: '1000'}}
                               severity="error" variant="filled">
                            {error1}
                        </Alert>
                    }

                    <div className={homeStyle.formContainer}>
                        <CircularProgress sx={{color: '#f12218'}}/>
                    </div>

                </div>
                {/*TODO: Footer*/}
                <Footer></Footer>
            </div>
        )
    }

    if(error1) {
        return (
            <div style={{width: '100vw', position: 'relative'}}>
                <Header></Header>
                <div className={homeStyle.mainContainer}>
                    <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                    <img src={bkgDown} className={homeStyle.backgroundImgDown}/>


                    <div className={homeStyle.formContainer}>
                        <div style={{textAlign: "center", width: "65%"}}>
                            <h2>Il link non è valido.</h2>
                        </div>
                    </div>

                </div>
                {/*TODO: Footer*/}
                <Footer></Footer>
            </div>
        )
    }

    if(data?.modifiedDocument) {
        return (
            <div style={{width: '100vw', position: 'relative'}}>
                <Header></Header>
                <div className={homeStyle.mainContainer}>
                    <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                    <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                    {error1 &&
                        <Alert style={{position: "fixed", left: '2vw', bottom: '2vh', color: 'white', zIndex: '1000'}}
                               severity="error" variant="filled">
                            {error1}
                        </Alert>
                    }

                    <div className={homeStyle.formContainer}>
                        <div style={{textAlign: "center", width: "65%"}}>
                            <h2>La tua risposta è stata registrata con successo!</h2>
                            <p>Per qualsiasi necessità non esitare a contattare la Segreteria Organizzativa
                                all’indirizzo <br/>
                                <a
                                    href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a></p>
                        </div>
                    </div>

                </div>
                {/*TODO: Footer*/}
                <Footer></Footer>
            </div>
        )
    }

    return (
        <div style={{width: '100vw', position: 'relative'}}>
            <Header></Header>
            <div className={homeStyle.mainContainer}>
                <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                {error1 &&
                    <Alert style={{position: "fixed", left: '2vw', bottom: '2vh', color: 'white', zIndex: '1000'}}
                           severity="error" variant="filled">
                        {error1}
                    </Alert>
                }

                <div className={homeStyle.formContainer}>
                    {success &&
                        <div style={{textAlign: "center", width: "65%"}}>
                            <h2>La tua risposta è stata registrata con successo!</h2>
                            <p>Per qualsiasi necessità non esitare a contattare la Segreteria Organizzativa
                                all’indirizzo <br/>
                                <a
                                    href={"mailto:segreteria@we-days2024.it"}>segreteria@we-days2024.it</a></p>
                        </div>
                    }
                    {!success &&
                        <>
                            <h1 className={homeStyle.formTitle}>
                            <span
                                style={{fontWeight: '500', color: '#E51576'}}
                            >
                                 VERIFICA I TUOI DATI
                            </span>
                            </h1>
                            <h3 className={homeStyle.formSubtitle} style={{marginBottom:"1vh"}}>
                                Ti preghiamo di verificare attentamente i dati del documento inseriti durante la fase di registrazione,
                                <span style={{fontWeight:"bold"}}> necessari per l'accredito presso le strutture ricettive</span>.
                            </h3>
                            <h3 className={homeStyle.formSubtitle} style={{marginTop:0}}>
                                Se noti qualche errore o omissione, ti chiediamo di  <span style={{fontWeight:"bold"}}>correggerli prima di procedere</span>.
                            </h3>
                            {/* FORM DI REGISTRAZIONE */}
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2} justifyContent="center"
                                      sx={{width: '95%', marginLeft: 0}}
                                      alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            label="Nome"
                                            name={'nome'}
                                            value={formValues.nome}
                                            helperText={" "}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            label="Cognome"
                                            name={'cognome'}
                                            value={formValues.cognome}
                                            helperText={" "}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomTextField
                                            label="E-mail"
                                            name={'email'}
                                            value={formValues.email}
                                            helperText={" "}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={10}>
                                        <h4 className={homeStyle.formSection}>
                                            INDICA I DATI COMPLETI
                                        </h4>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <CustomTextField
                                            label="Nome(come da documento)"
                                            name={'nomeDocumento'}
                                            value={formValues.nomeDocumento}
                                            error={formErrors.nomeDocumento}
                                            helperText={formErrors.nomeDocumento || " "}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <CustomTextField
                                            label="Cognome(come da documento)"
                                            name={'cognomeDocumento'}
                                            value={formValues.cognomeDocumento}
                                            error={formErrors.cognomeDocumento}
                                            helperText={formErrors.cognomeDocumento || " "}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <h4 className={homeStyle.formSection}>
                                            TIPOLOGIA DOCUMENTO
                                        </h4>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={'tipoDocumento'}
                                            value={formValues.tipoDocumento} // Imposta il valore selezionato sul RadioGroup
                                            onChange={handleChange} // Gestisci il cambiamento di selezione
                                        >
                                            <CustomFormControlLabel value="identita"
                                                                    control={<CustomRadio size="large"/>}
                                                                    label="CARTA IDENTITÀ"/>
                                            <CustomFormControlLabel value="passaporto"
                                                                    control={<CustomRadio size="large"/>}
                                                                    label="PASSAPORTO"/>
                                        </RadioGroup>
                                        <p className={homeStyle.errorMessage}>{formErrors.tipoDocumento}</p>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <CustomTextField
                                            label="N. DOCUMENTO"
                                            name={'numeroDocumento'}
                                            value={formValues.numeroDocumento}
                                            error={formErrors.numeroDocumento}
                                            helperText={formErrors.numeroDocumento || " "}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <h4 className={homeStyle.formSection}>
                                            DATA DI EMISSIONE
                                        </h4>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={formValues.dataEmissione}
                                                name={'dataEmissione'}
                                                format={'DD/MM/YYYY'}
                                                onChange={(newValue) => handleDateChange('dataEmissione', newValue)}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: 'Edenred',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: formErrors.dataEmissione ? '#d32f2f' : 'grey',
                                                            borderWidth: '2px',
                                                            borderRadius: '20px',
                                                            fontFamily: 'Edenred, sans-serif'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Boolean(formErrors.dataEmissione) ? '#d32f2f' : 'primary.main', // change to theme's primary color when focused if no error
                                                        }
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <p className={homeStyle.errorMessage}>{formErrors.dataEmissione}</p>
                                    </Grid>
                                    <Grid item xs={6} md={5}>
                                        <h4 className={homeStyle.formSection}>
                                            DATA DI SCADENZA
                                        </h4>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={formValues.dataScadenza}
                                                name={'dataScadenza'}
                                                format={'DD/MM/YYYY'}
                                                onChange={(newValue) => handleDateChange('dataScadenza', newValue)}
                                                sx={{
                                                    '& .MuiInputBase-root': {
                                                        fontFamily: 'Edenred',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: formErrors.dataScadenza ? '#d32f2f' : 'grey',
                                                            borderWidth: '2px',
                                                            borderRadius: '20px',
                                                            fontFamily: 'Edenred, sans-serif'
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: Boolean(formErrors.dataScadenza) ? '#d32f2f' : 'primary.main', // change to theme's primary color when focused if no error
                                                        }
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <p className={homeStyle.errorMessage}>{formErrors.dataScadenza}</p>
                                    </Grid>
                                </Grid>

                                <div className={homeStyle.buttonContainer}>
                                    {isLoading ? (
                                        <CircularProgress sx={{color: '#f12218'}}/>
                                    ) : (
                                        <CustomButton
                                            type="submit"
                                        >
                                            CONFERMA
                                        </CustomButton>
                                    )}
                                </div>
                            </form>
                        </>
                    }
                </div>

            </div>
            {/*TODO: Footer*/}
            <Footer></Footer>
        </div>
    )

}

export default Document;