import {createTheme, responsiveFontSizes} from "@mui/material";
import {green} from "@mui/material/colors";

export const edenredTheme = responsiveFontSizes(createTheme(
    {
        typography: {
            fontFamily: ['Edenred', 'sans-serif'].join(','),
            color: green,
        },
        palette: {
            background:{
                default:'#fff'
            },
            primary: {
                main:'#EF4231',
                contrastText:'#fff'
            },
            secondary: {
                main: '#000'
            }
        }
    }
))
