export const hotelsData = {
    'HILTON SORRENTO PALACE': {
        nome:'Hilton Sorrento Palace 4*',
        indirizzo:'Via Rivolo S.Antonio, 13, 80067 Sorrento NA',
        tel:'081 878 4141',
        website:'https://www.hilton.com/it/hotels/rrohihi-hilton-sorrento-palace/'
    },
    'ARTHOTEL GRAN PARADISO': {
        nome:'Art Hotel Gran Paradiso 4*',
        indirizzo:'Via Catigliano, 9, 80067 Sorrento NA',
        tel:'081 807 3700',
        website:'https://arthotelgranparadiso.com/'
    },
    'EUROPA PALACE': {
        nome:'Grand Hotel Europa Palace 4*',
        indirizzo:'Via Correale, 34/36, 80067 Sorrento NA',
        tel:'081 807 4898',
        website:'https://www.europapalacesorrento.com/'
    },
}