import {firebase} from "../firebase/clientApp";

export const getAuthToken = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const token = await user.getIdToken();
                    resolve(`Bearer ${token}`);
                } catch (err) {
                    console.error("Error getting token:", err);
                    reject(null);
                }
            } else {
                console.error("No user is currently authenticated.");
                reject(null); // Or handle as needed
            }
        });
    });
};
