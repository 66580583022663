import {useRef, useState} from "react";
import {auth} from "../firebase/clientApp";
import loginStyle from "./Login.module.css";
import Header from "../components/Header";
import {Button, CircularProgress, Collapse, Link, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {ArrowBack} from "@mui/icons-material";
import Footer from "../components/Footer";
import bkgUp from "../assets/images/bkg-up.svg";
import homeStyle from "./Home.module.css";
import bkgDown from "../assets/images/bkg-down.svg";
import {CustomButton, CustomTextField, SmallCustomButton} from "../assets/mui-styles/styles";
import {useNavigate} from "react-router-dom";


export const Recover = () => {
    const email = useRef(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const recoverPassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.sendPasswordResetEmail(email.current.value)
            .then((userCredential) => {
                setSuccess(true)
            })
            .catch((error) => {
                setError(true)
            });
        setLoading(false)
    }
    return (
        <div className={loginStyle.mainContainer}>
            <Header></Header>
            <div className={loginStyle.loginContainer}>
                <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                <div className={loginStyle.loginPanel}>
                    <Box sx={{p: 2}}>
                        <SmallCustomButton startIcon={<ArrowBack/>} variant={'outlined'} onClick={() => {navigate('/login')}}>
                            Torna alla login
                        </SmallCustomButton>
                    </Box>
                    <form onSubmit={recoverPassword}>
                        <CustomTextField type={'email'} inputRef={email} fullWidth label={'Email'} variant={'standard'}
                                   sx={{pb: 2}}/>
                        <Box sx={{textAlign: 'center', p: 2}}>
                            {loading ? <CircularProgress/> : <SmallCustomButton variant={'contained'} type={'submit'}>
                                recupera password
                            </SmallCustomButton>}
                        </Box>
                    </form>
                    <Collapse in={success}>
                        <Typography sx={{pb: 2}} color={'green'} variant={'body2'} textAlign={'center'}>
                            Ti abbiamo inviato una mail per il reset password. <br/>
                            Se non ricevi la mail CONTROLLA ANCHE IN SPAM.
                            In caso di necessità contatta la segreteria all'indirizzo
                            <a href={"mailto:segreteria@we-days2024.it"}> segreteria@we-days2024.it</a>
                        </Typography>
                    </Collapse>
                    <Collapse in={error}>
                        <Typography sx={{pb: 2}} color={'red'} variant={'body2'} textAlign={'center'}>
                            Errore nell'invio della mail
                        </Typography>
                    </Collapse>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}