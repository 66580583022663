import loginStyle from './Login.module.css';
import bkgUp from "../assets/images/bkg-up.svg";
import homeStyle from "./Home.module.css";
import bkgDown from "../assets/images/bkg-down.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useState} from "react";
import {CircularProgress, Grid, IconButton, InputAdornment, Link} from "@mui/material";
import {CustomButton, CustomTextField} from "../assets/mui-styles/styles";
import {Email, Lock, Visibility, VisibilityOff} from "@mui/icons-material";
import {auth} from "../firebase/clientApp";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

function Register() {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()

    const [formValues, setFormValues] = useState({
        email: '',
        password:''
    });

    const doLogin = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        await auth.signInWithEmailAndPassword(formValues.email, formValues.password)
            .then((userCredential) => {
                if (window.Tawk_API) {
                    console.info(window.Tawk_API)
                    window.Tawk_API.visitor = {
                        name: "TEST NAME",
                        email: formValues.email
                    };
                }
                navigate('/home', {replace: true})
            })
            .catch((error) => {
                setError(true)
            });
        setIsLoading(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={loginStyle.mainContainer}>
            <Header></Header>
            <div className={loginStyle.loginContainer}>
                <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                <h1 style={{position:'relative', fontWeight: 'normal', color:'#E51576', textDecoration:'underline', marginTop:0, zIndex:'2'}}>
                    WE-DAYS
                </h1>
                <div className={loginStyle.loginPanel}>
                    <form onSubmit={doLogin} >
                        <Grid container spacing={2} justifyContent="center"
                              style={{marginTop:'30px'}}
                              sx={{width: '95%', marginLeft: 0}}
                              alignItems="center">
                            <Grid item xs={12} md={12}>
                                <CustomTextField
                                    type={'email'}
                                    label="Email"
                                    name={'email'}
                                    value={formValues.email}
                                    onChange={handleChange}
                                    helperText={" "}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email sx={{ color: '#E51576' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomTextField
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    name={'password'}
                                    value={formValues.password}
                                    onChange={handleChange}
                                    helperText={''}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock sx={{ color: '#E51576' }} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility sx={{ color: '#E51576' }} /> : <VisibilityOff sx={{ color: '#E51576' }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {error &&
                                <Typography style={{textAlign:'center', color:'#f12218'}}>
                                    E-mail e/o password errati
                                </Typography>
                            }
                        </Grid>
                        <div className={loginStyle.buttonContainer} style={{marginTop:'15px'}}>
                            {isLoading ? (
                                <CircularProgress sx={{color: '#f12218'}}/>
                            ) : (
                                <CustomButton
                                    type="submit"
                                >
                                    ACCEDI
                                </CustomButton>
                            )}
                            <p
                                style={{marginTop:'2%', marginBottom:'-2%', cursor:'pointer', textDecoration:'underline', color:'#e51575'}}
                                onClick={() => {navigate('/recover')}}
                            >
                                Password dimenticata?
                            </p>
                        </div>

                    </form>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Register;