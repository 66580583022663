import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";

import teamStyle from "./Teams.module.css";

import testBack from "../assets/images/retro.png";
import axios from "axios";
import {API_URL} from "../config";
import {CircularProgress} from "@mui/material";
import {cardsData} from "../data/cards";


function Teams() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [success, setSuccess] = useState(false);

    const [revealedProfileId, setRevealedProfileId] = useState(null);
    const [cardPosition, setCardPosition] = useState({ x: 0, y: 0 });

    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 600px)").matches);

    const [teams, setTeams] = useState([
        { id: 1, image: null },
        { id: 2, image: null },
        { id: 3, image: null },
        { id: 4, image: null },
    ])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_URL}profile/${id}`);
                setData(response.data); // Axios incapsula i dati risposta in `response.data`
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if(data) {
            setTeams([
                {id: 1, image: cardsData[data.squadraSmorfia]},
                {id: 2, image: cardsData[data.squadraSmorfia]},
                {id: 3, image: cardsData[data.squadraSmorfia]},
                {id: 4, image: cardsData[data.squadraSmorfia]},
            ])
        }

    }, [data]);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        const handleMediaChange = (event) => {
            setIsMobile(event.matches);
        };

        // Attach listener
        mediaQuery.addEventListener("change", handleMediaChange);

        // Clean up listener on component unmount
        return () => {
            mediaQuery.removeEventListener("change", handleMediaChange);
        };
    }, []);

    const handleReveal = (profileId, event) => {
        if (revealedProfileId) return;
        setSuccess(true);

        const cardRect = event.currentTarget.getBoundingClientRect();
        const containerRect = event.currentTarget.parentElement.getBoundingClientRect();

        const containerCenterX = containerRect.left + containerRect.width / 2;
        const containerCenterY = containerRect.top + containerRect.height / 2;
        const cardCenterX = cardRect.left + cardRect.width / 2;
        const cardCenterY = cardRect.top + cardRect.height / 2;

        const xOffset = containerCenterX - cardCenterX;
        const yOffset = containerCenterY - cardCenterY;

        // Divisori più generali
        const xDiv = isMobile ? 1.2 : 1.5;
        const yDiv = isMobile ? 1.5 : 1.8;

        setCardPosition({
            x: xOffset / xDiv,
            y: yOffset / yDiv,
        });

        setRevealedProfileId(profileId);
        chooseCard();
    };

    const chooseCard = async () => {
        try {
            const response = await axios.post(`${API_URL}card/${id}`, {});
            console.log('Response:', response.data);
        } catch (error) {
            console.error('There was an error making the POST request:', error);
        }
    };


    if (!data || data?.squadraSmorfia === 'no') {
        if (loading) {
            return (
                <div style={{
                    width: "100vw",
                    height: "100vh",
                    alignContent: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <CircularProgress></CircularProgress>
                </div>
            )
        }
        return (
            <div style={{
                width: "100vw",
                height: "100vh",
                alignContent: 'center',
                justifyContent:'center', textAlign:'center'}}>
                <h1>Utente non abilitato</h1>
            </div>
        )
    }

    if(data?.cardClicked) {
        return(
            <div className={teamStyle.mainContainer}>
                <h3 className={teamStyle.title}>
                    Complimenti, {data.nome}
                    <br/>
                    <span style={{fontSize: '17px'}}>Hai scelto con successo la tua squadra!</span>
                    <span style={{fontSize: '17px'}}>Ci vediamo a Sorrento per vivere insieme questa fantastica avventura!</span>
                </h3>
                <div className={teamStyle.cardsContainer}>
                    <img src={teams[0].image} alt={'team'} className={teamStyle.cardRevealed}/>
                </div>
            </div>
        )
    }

    if (data) {
        return (
            <div className={teamStyle.mainContainer}>
                {!success &&
                    <h3 className={teamStyle.title}>
                        Ciao {data.nome}, scegli una carta e scopri la tua squadra!
                    </h3>
                }
                {success &&
                    <>
                        <h3 className={teamStyle.title}>
                            Complimenti, {data.nome}
                            <br/>
                            <span style={{fontSize: '17px'}}>Hai scelto con successo la tua squadra!</span>
                            <span style={{fontSize: '17px'}}>Ci vediamo a Sorrento per vivere insieme questa fantastica avventura!</span>
                        </h3>
                    </>

                }

                <div className={teamStyle.cardsContainer}>
                    <div className={`${teamStyle.cardsGrid} ${revealedProfileId ? teamStyle.revealed : ''}`}>
                        {teams.map((profile) => (
                            <div
                                key={profile.id}
                                className={`${teamStyle.teamCard} ${revealedProfileId === profile.id ? teamStyle.flipped : ''} ${revealedProfileId === profile.id ? teamStyle.centered : ''}`}
                                onClick={(event) => handleReveal(profile.id, event)}
                                style={revealedProfileId === profile.id ? {transform: `scale(1.3) translate(${cardPosition.x}px, ${cardPosition.y}px)`} : {}}
                            >
                                <div className={teamStyle.teamCardInner}>
                                    <div className={teamStyle.teamCardFront}>
                                        <img src={testBack} alt="Back"/>
                                    </div>
                                    <div className={teamStyle.teamCardBack}>
                                        <img src={profile.image} alt={profile.name}/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

}

export default Teams;
