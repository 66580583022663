import React from "react";
import {Website} from "../layouts/Website";
import {PageTitle} from "../components/shared/PageTitle";
import {Container, Grid, Typography} from "@mui/material";
import {programMap} from "../data/programMap";
import {CardProgramma} from "../components/Programma/CardProgramma";

export const Programma = () => {
    return (
        <Website>
            <Container sx={{ pt:6 }}>
                <PageTitle title={'Programma di viaggio'} image={'icon-program'}/>
                <Grid container spacing={2} sx={{pt:2}}>
                    {
                        programMap.map((day, index) =>
                            <Grid item xs={12} md={6} lg={4} key={index}>
                                <CardProgramma day={day} i={index}/>
                            </Grid>
                        )}
                </Grid>
                <Typography fontWeight="bold" padding="15px">*Gli orari potrebbero subire variazioni.</Typography>
            </Container>
        </Website>
    )
}
