import React from "react";
import {Website} from "../layouts/Website";
import {Box, Container, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";
import {dressMap} from "../data/dressMap";

export const DressCode = () => {
    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6}}>
                <PageTitle title={'MOOD'} image={'icon-dress'}/>
                {/*<Container>
                    <img src={require('../assets/images/mood-img.png')} alt={'dress-code'} style={{width:'100%'}}/>
                </Container>*/}
                {
                    dressMap.map((dress, index) =>
                        <Box>
                            <Typography color={index === 0 ? '#E51576' : 'black'}
                                        fontWeight={index === 0 ? 'bold' : ''}
                                        variant={index === 0 ? "h6" : 'body1'}
                                        sx={{textDecoration: index === 0 ? 'none' : 'underline'}}
                            >
                                {dress.title}
                            </Typography>

                            <Typography>
                                {dress.description}
                            </Typography>
                        </Box>
                    )}
            </Container>
        </Website>
    )
}
