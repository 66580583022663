import loginStyle from './Login.module.css';
import bkgUp from "../assets/images/bkg-up.svg";
import homeStyle from "./Home.module.css";
import bkgDown from "../assets/images/bkg-down.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {useEffect, useState} from "react";
import {CircularProgress, Grid, IconButton, InputAdornment, Link} from "@mui/material";
import {CustomButton, CustomTextField} from "../assets/mui-styles/styles";
import {Email, Lock, Visibility, VisibilityOff} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../config";

function Register() {
    const {id} = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [pswError, setPswError] = useState(false);

    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        email: '',
        password:''
    });

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}profile/${id}`);
                setData(response.data); // Axios incapsula i dati risposta in `response.data`
                setFormValues({
                    ...formValues,
                    email: response.data.email
                });
                if(response.data.registered) {
                    navigate('/login');
                }
            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [id]);

    function handleSubmit(){
        if(formValues.password.length >= 8) {
            setPswError(false);
            setIsLoading(true);
            axios.post(`${API_URL}signup`, {id, email: formValues.email, password:formValues.password, nome:data.nome, cognome:'test'})
                .then(res => {
                    setSuccess(true);
                    setIsLoading(false);
                })
                .catch(err => {
                    // TODO : GESTIRE ERRORE
                    //console.info(err.response.data.error);
                    setError(true);
                    setIsLoading(false);
                })
        } else {
            setPswError(true);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={loginStyle.mainContainer}>
            <Header></Header>
            <div className={loginStyle.loginContainer}>
                <img src={bkgUp} className={homeStyle.backgroundImgUp}/>
                <img src={bkgDown} className={homeStyle.backgroundImgDown}/>
                <h1 style={{position:'relative', fontWeight: 'normal', color:'#E51576', textDecoration:'underline', marginTop:0, zIndex:'2'}}>
                    WE-DAYS
                </h1>
                {!success ? <div className={loginStyle.loginPanel}>
                        <h4 style={{fontWeight: 'normal', marginBottom:0}}>
                            CREA LE TUE CREDENZIALI PER ACCEDERE
                        </h4>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justifyContent="center" padding='0'
                                  sx={{width: '95%', marginLeft: 0, margin:'auto'}}>
                                <Grid item xs={12} md={12} sx={{pl:0}}>
                                    <CustomTextField
                                        type={'email'}
                                        label="Email"
                                        name={'email'}
                                        value={formValues.email}
                                        helperText={" "}
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email sx={{color: '#E51576'}}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <CustomTextField
                                        type={showPassword ? 'text' : 'password'}
                                        label="Password"
                                        name={'password'}
                                        value={formValues.password}
                                        onChange={handleChange}
                                        helperText={" "}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock sx={{color: '#E51576'}}/>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility sx={{color: '#E51576'}}/> :
                                                            <VisibilityOff sx={{color: '#E51576'}}/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {pswError && <p style={{textAlign: 'center', color: '#f12218'}}>La password deve contenere almeno 8
                                caratteri</p>}
                            {error && <p style={{textAlign: 'center', color: '#f12218'}}>Si è verificato un errore, riprovare</p>}
                            <div className={loginStyle.buttonContainer} style={{marginTop: '0 !important', marginBottom:'10px'}}>
                                {isLoading ? (
                                    <CircularProgress sx={{color: '#f12218'}}/>
                                ) : (
                                    <CustomButton
                                        onClick={() => handleSubmit()}
                                    >
                                        REGISTRATI
                                    </CustomButton>
                                )}
                            </div>

                        </form>
                    </div>
                    :
                    <div className={loginStyle.loginPanel}>
                        <h4 style={{fontWeight: 'normal', textAlign:'center', padding:'4px'}}>
                            Hai completato correttamente la procedura di registrazione, ora puoi accedere al tuo account
                        </h4>

                        <div className={loginStyle.buttonContainer} style={{marginTop: '0 !important'}}>
                            <CustomButton
                                onClick={() => {navigate('/login')}}
                            >
                                 EFFETTUA IL LOGIN
                            </CustomButton>
                        </div>

                    </div>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Register;