
export const programMap = [
    {
        day: '26 settembre 2024',
        image: "programma-giorno-1.jpg",
        sections: [
            {
                title: 'Ore 13:00',
                activities: [
                    "Trasferimento da Napoli per Sorrento",
                    "",
                    "Arrivo a Sorrento e check-in nei rispettivi hotel presso l’hospitality desk Edenred",
                ]
            },
            {
                title: 'Ore 16:45',
                activities: [
                    "Appuntamento nella hall dell'hotel di appartenenza per trasferimento verso Hilton Sorrento Palace\n"
                ]
            },
            {
                title: 'Ore 17:30 - 19:30',
                activities: [
                    "Plenaria ed Ewards presso Auditorium dell’Hilton Sorrento Palace"
                ]
            },
            {
                title: 'Ore 20:00',
                activities: [
                    "Welcome dinner presso la piscina dell’Hilton Sorrento Palace",
                    "Dopocena libero"
                ]
            },
            {
                title: 'Ore 22:30 e 23:30',
                activities: [
                    "Trasferimento verso l’hotel di appartenenza (pullman a riempimento)"
                ]
            }
        ]
    },
    {
        day: '27 settembre 2024',
        image: "programma-giorno-2.jpg",
        sections: [
            {
                title: 'Ore 07:00 - 08:00',
                activities: [
                    "Colazione presso l’hotel di appartenenza"
                ]
            },
            {
                title: 'Ore 08:15',
                activities: [
                    "Trasferimento dai vari hotel per Hilton Sorrento Palace"
                ]
            },
            {
                title: 'Ore 09:00 - 11:20',
                activities: [
                    "Convention WE-DAYS in Auditorium presso l’Hilton Sorrento Palace (1a parte)"
                ]
            },
            {
                title: 'Ore 11:20 - 11:40',
                activities: [
                    "Coffee break"
                ]
            },
            {
                title: 'Ore 11:40 - 13:30',
                activities: [
                    "Convention WE-DAYS in Auditorium presso l’Hilton Sorrento Palace (2a parte)"
                ]
            },
            {
                title: 'Ore 13:30 - 14:30',
                activities: [
                    "Pranzo in Agrumeto"
                ]
            },
            {
                title: 'Ore 15:00 - 17:00',
                activities: [
                    "Attività di team building a Sorrento",
                    "",
                    "Rientro a piedi nei rispettivi hotel, ad esclusione dell’Art Hotel Gran Paradiso con minibus dal Parcheggio Angelina Laura (via Correale)"
                ]
            },
            {
                title: 'Dalle ore 19:00',
                activities: [
                    "Trasferimento dai vari hotel per il Sopramare Resort secondo i seguenti orari:"
                ]
            },
            {
                title: '',
                activities: [
                    <>
                         ore 19:00 <span style={{fontWeight:'bold'}}>Hilton Sorrento Palace</span> <br/>
                        - ore 19:00 <span style={{fontWeight:'bold'}}>Art Hotel Gran Paradiso</span> <br/>
                        - ore 19:40 <span style={{fontWeight:'bold'}}>Grand Hotel Europa Palace</span>
                    </>
                ]
            },
            {
                title: 'Ore 20:00',
                activities: [
                    "«PIEDIGROTTA D'A MARE» - cena sotto le stelle e dj set"
                ]
            },
            {
                title: 'Ore 23:00 - 00:30',
                activities: [
                    "Trasferimento verso gli hotel dal punto di rilascio dei pullman (i bus partiranno ogni mezz’ora, disponibilità fino ad esaurimento posti)"
                ]
            }
        ]
    },
    {
        day: '28 settembre 2024',
        image: "programma-giorno-3.jpg",
        sections: [
            {
                title: 'Ore 07:00 - 09:00',
                activities: [
                    "Colazione presso l’hotel di appartenenza",
                ]
            },
            {
                title: 'Entro le Ore 09:30',
                activities: [
                    "Check-out e pagamento dei propri extra"
                ]
            },
            {
                title: '',
                activities: [
                    "Transfer e rientro dall'hotel di appartenenza secondo i seguenti orari:"
                ]
            },
            {
                title: 'Ore 08:00',
                activities: [
                    "- partenza del gruppo Palermo (volo EZY 4101) per rientro da Napoli Capodichino"
                ]
            },
            {
                title: 'Dalle ore 09:30',
                activities: [
                    <>
                        - partenza del gruppo Lazio, Liguria, Lombardia, Piemonte (treno charter) per rientro da Napoli Centrale a Roma Tiburtina e Milano Centrale (pullman a riempimento)<span style={{color:'#e3007c'}}>*</span> secondo i seguenti orari:

                        <br/> <a href={'/elenco-destinazioni-treno-charter.pdf'} target="_blank" rel="noopener noreferrer" style={{color:'#e3007c', textDecoration:'underline'}}>*vedi
                        qui elenco destinazioni</a>
                    </>
                ]
            },
            {
                title: '',
                activities: [
                    <>
                        - ore 09:30 <span style={{fontWeight:'bold'}}>Hilton Sorrento Palace</span> <br/>
                        - ore 09:45 <span style={{fontWeight:'bold'}}>Art Hotel Gran Paradiso</span> <br/>
                        - ore 09:45 <span style={{fontWeight:'bold'}}>Grand Hotel Europa Palace</span>
                    </>
                ]
            },
            {
                title: 'ore 11:00',
                activities: [
                    <>
                        - partenza del gruppo Toscana, Emilia Romagna, Veneto, Trentino Alto Adige (treno FR 9426) per rientro da Napoli Centrale<span style={{color:'#e3007c'}}>*</span> <br/> <a href={'/elenco-destinazioni-nord-est.pdf'} target="_blank" rel="noopener noreferrer" style={{color:'#e3007c', textDecoration:'underline'}}>*vedi
                        qui elenco destinazioni</a>
                    </>
                ]
            },
            {
                title: 'ore 16:00',
                activities: [
                    <>
                        - partenza del gruppo Catania (volo FR 4320) per rientro da Napoli Capodichino
                    </>
                ]
            },
        ]
    }
    /*{
        day: '22 settembre 2023',
        image: "programma-giorno-2.jpg",
        sections: [
            {
                title: 'Mattina',
                activities: [
                    <><i><b>dalle ore 07:30 alle ore 10:30</b></i> - Colazione presso l’hotel di appartenenza</> ,
                    <><i><b>ore 10:00</b></i> - Sales Meeting in sala Rhenia Conference presso l'hotel Imperial</>,
                    <><i><b>ore 13:00</b></i> - Pranzo al ristorante Celebrities - Meltemi & Terraces presso l'hotel Imperial</>
                ]
            },
            {
                title: 'Pomeriggio',
                activities: [
                    "Tempo libero"
                ]
            },
            {
                title: 'Sera',
                activities: [
                    <><i><b>ore 20:30</b></i> - White Party presso la piscina dell’hotel Imperial </>
                ]
            }
        ]
    },
    {
        day: '23 settembre 2023',
        image: "programma-giorno-3.jpg",
        sections: [
            {
                title: 'Mattina',
                activities: [
                    <><i><b>dalle ore 07:30 alle ore 10:30</b></i> - Colazione presso l’hotel di appartenenza e check-out</>,
                    <span>
                        Transfer e rientro dall'hotel di appartenenza secondo i seguenti orari:
                        <ul style={{paddingLeft:15}}>
                            <li><b>Volotea V71611<br/>ore 08.00</b> - partenza gruppo per rientro su Napoli</li>
                            <li><b>Easyjet U23666<br/>ore 12.45</b> - partenza gruppo per rientro su Milano Malpensa</li>
                            <li><b>Charter ITA Airways AZ9057<br/>ore 13.30</b> - partenza gruppo per rientro su Milano Malpensa</li>
                        </ul>
                    </span>
                ]
            }
        ]
    }*/
]
