import team11 from '../assets/cards/team-11.png'
import team12 from '../assets/cards/team-12.png'
import team14 from '../assets/cards/team-14.png'
import team15 from '../assets/cards/team-15.png'
import team19 from '../assets/cards/team-19.png'
import team20 from '../assets/cards/team-20.png'
import team22 from '../assets/cards/team-22.png'
import team24 from '../assets/cards/team-24.png'
import team34 from '../assets/cards/team-34.png'
import team42 from '../assets/cards/team-42.png'
import team45 from '../assets/cards/team-45.png'
import team46 from '../assets/cards/team-46.png'
import team48 from '../assets/cards/team-48.png'
import team50 from '../assets/cards/team-50.png'
import team53 from '../assets/cards/team-53.png'
import team54 from '../assets/cards/team-54.png'
import team55 from '../assets/cards/team-55.png'
import team57 from '../assets/cards/team-57.png'
import team61 from '../assets/cards/team-61.png'
import team65 from '../assets/cards/team-65.png'
import team72 from '../assets/cards/team-72.png'
import team75 from '../assets/cards/team-75.png'
import team77 from '../assets/cards/team-77.png'
import team78 from '../assets/cards/team-78.png'
import team79 from '../assets/cards/team-79.png'
import team81 from '../assets/cards/team-81.png'
import team87 from '../assets/cards/team-87.png'
import team88 from '../assets/cards/team-88.png'
import team89 from '../assets/cards/team-89.png'
import team90 from '../assets/cards/team-90.png'

export const cardsData = {
    11: team11,
    12: team12,
    14: team14,
    15: team15,
    19: team19,
    20: team20,
    22: team22,
    24: team24,
    34: team34,
    42: team42,
    45: team45,
    46: team46,
    48: team48,
    50: team50,
    53: team53,
    54: team54,
    55: team55,
    57: team57,
    61: team61,
    65: team65,
    72: team72,
    75: team75,
    77: team77,
    78: team78,
    79: team79,
    81: team81,
    87: team87,
    88: team88,
    89: team89,
    90: team90
}
