import {Website} from "../layouts/Website";
import {Box, Container, Typography} from "@mui/material";
import {PageTitle} from "../components/shared/PageTitle";
import {contactsMap} from "../data/contactsMap";
import React, {useEffect, useState} from "react";
import client from "../client";

import newsAggiornamento from "../assets/images/news-star-aggiornamento.svg";
import newsTravel from "../assets/images/news-star-travel.svg";
import newsEvento from "../assets/images/news_icon.svg";

export const News = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        console.info(news);
        client.fetch(`*[_type == "news"]{
      title,
      content,
      type,
      "imageUrl": image.asset->url
    }`).then(data => {
            //console.info(data)
            setNews(data)
        })
            .catch(console.error);
    }, []);

    const getColor = (type) => {
        switch(type) {
            case 'evento': return '#e31c72';
            case 'travel&accomodation': return '#921b7f';
            case 'aggiornamento': return '#1d70b5';
            default: return 'white';
        }
    };


    return (
        <Website>
            <Container maxWidth={'xxl'} sx={{pb: 2, pt: 6}}>
                <PageTitle title={'news'} image={'icon-news'}/>
                <Box>
                    {news.length === 0 &&
                        <Box style={{margin:'auto',height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <Typography variant={'h5'} style={{margin:'auto'}} textAlign={'center'}>
                                Non ci sono ancora News, qui vedrai tutte le comunicazioni e aggiornamenti riguardanti l'evento
                            </Typography>
                        </Box>
                    }
                    {news.map((item, index) => (
                        <div key={index} style={{ padding: '5px', margin: '10px 0', borderRadius:'1rem' }}>
                            {item.type === 'aggiornamento' &&
                                <div style={{display:"flex", flexDirection:'row'}}>
                                    <img style={{width: '130px', marginRight:'10px'}} src={newsAggiornamento} alt={''} />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <h3 style={{
                                            color: getColor(item.type),
                                            borderLeft: '3px solid #1d70b5',
                                            margin: 0,
                                            paddingLeft:'5px'
                                        }}>
                                            NEWS! #aggiornamento
                                        </h3>
                                        <h5 style={{margin: 0}}>{item.title}</h5>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            }
                            {item.type === 'travel&accomodation' &&
                                <div style={{display:"flex", flexDirection:'row'}}>
                                    <img style={{width: '130px', marginRight:'10px'}} src={newsTravel} alt={''} />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <h3 style={{
                                            color: getColor(item.type),
                                            borderLeft: '3px solid #921b7f',
                                            margin: 0,
                                            paddingLeft:'5px'
                                        }}>
                                            NEWS! #travel
                                        </h3>
                                        <h5 style={{margin: 0}}>{item.title}</h5>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            }
                            {item.type === 'evento' &&
                                <div style={{display:"flex", flexDirection:'row'}}>
                                    <img style={{width: '130px', marginRight:'10px'}} src={newsEvento} alt={''} />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: 'left',
                                        justifyContent: 'flex-start'
                                    }}>
                                        <h3 style={{
                                            color: getColor(item.type),
                                            borderLeft: '3px solid #e31c72',
                                            margin: 0,
                                            paddingLeft:'5px'
                                        }}>
                                            NEWS! #evento
                                        </h3>
                                        <h5 style={{margin: 0}}>{item.title}</h5>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            }


                            {item.imageUrl && <img src={item.imageUrl} alt={item.title} style={{ width: '100%', height: 'auto' }} />}
                        </div>
                    ))}
                </Box>
            </Container>
        </Website>
    )
}