import footerStyle from "./Footer.module.css";

function Footer() {
    return (
        <div className={footerStyle.mainContainer}>
            <h2 className={footerStyle.text}> La Segreteria Organizzativa <span style={{color:"#E51576"}}>|</span> <span style={{fontWeight:'lighter'}}> <a href={"mailto:segreteria@we-days2024.it"} style={{color:"white"}}>segreteria@we-days2024.it</a></span></h2>
        </div>
    )
}

export default Footer;