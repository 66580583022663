import './App.css';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Document from "./pages/Document";
import Teams from "./pages/Teams";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomePage from "./pages/HomePage";
import Home from "./pages/Home";
import {Programma} from "./pages/Programma";
import {DressCode} from "./pages/DressCode";
import {Contacts} from "./pages/Contacts";
import {News} from "./pages/News";
import {Recover} from "./pages/Recover";
import {AreaPersonale} from "./pages/AreaPersonale";
import ProtectedRoute from "./components/shared/ProtectedRoute";

const router = createBrowserRouter([
    // FORM + CARTE
    { path: '/teams/:id', element: <Teams/>},
    { path: '/document/:id', element: <Document/>},
    { path: '/register/:id', element: <Home/>},
    { path: '/:id', element: <Home/>},

    // PIATTAFORMA
    { path: '/set-login/:id', element: <Register/>},
    { path: '/login', element: <Login/>},
    { path: '/recover', element: <Recover/>},
    // PROTETTE
    { path: '/area-personale', element:<ProtectedRoute><AreaPersonale/></ProtectedRoute> },
    { path: '/mood', element:<ProtectedRoute><DressCode/></ProtectedRoute> },
    { path: '/contatti', element:<ProtectedRoute><Contacts/></ProtectedRoute>},
    { path: '/programma', element:<ProtectedRoute> <Programma/></ProtectedRoute>},
    { path: '/news', element:<ProtectedRoute><News/></ProtectedRoute> },
    { path: '/home', element:<ProtectedRoute><HomePage/></ProtectedRoute>},
    { path: '/', element:<ProtectedRoute><HomePage/></ProtectedRoute>}
])

function App() {
  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <RouterProvider router={router} />
      </LocalizationProvider>
  );
}

export default App;
