import React from "react";
import {Box, ThemeProvider} from "@mui/material";
import Menu from "../components/Menu";
import {edenredTheme} from "../theme/edenredTheme";

export const Website = ({children}) => {
    return(
        <ThemeProvider theme={edenredTheme}>
            <Box width='100vw'>
                <Menu/>
                {children}
            </Box>
        </ThemeProvider>
    )
}
