import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import menuStyle from "./Menu.module.css";
import {
    Divider,
    Drawer,
    Slide,
    Stack,
    useMediaQuery, useTheme
} from "@mui/material";
import {NavLink, Link, useNavigate} from "react-router-dom";

import edenredLogo from '../assets/images/edenred-logo.svg'
import newsIcon from '../assets/images/icon-news.png';

const pages = [
    {
        id: 'home',
        title: 'Home',
        url: '/home',
        img: require('../assets/images/icon-home.png')
    },
    {
        id: 'programma',
        title: 'Programma di viaggio',
        url: '/programma',
        img: require('../assets/images/icon-program.png')
    },
    {
        id: 'mood',
        title: 'MOOD',
        url: '/mood',
        img: require('../assets/images/icon-dress.png')
    },
    {
        id: 'contatti',
        title: 'contatti',
        url: '/contatti',
        img: require('../assets/images/icon-contacts.png')
    },
    /*{
        id: 'foto',
        title: 'Foto/Video',
        url: '/foto-e-video',
        img: require('../assets/images/icon-foto-e-video.png')
    },*/
    {
        id: 'area-personale',
        title: 'area personale',
        url: '/area-personale',
        img: require('../assets/images/icon-area-personale.png')
    },



]

export const Menu = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const navigate = useNavigate();

    const handleNavigate = (url) => {
        navigate(url);
    };
    const navigateNews = () => {
        navigate('/news');
    };

    const drawer = (
        <Box>
            <Toolbar/>
            <Divider/>
            <Stack spacing={4} direction={'column'} sx={{width: '100%', height: '100%', p: 2}} alignItems={'left'}>
                {pages.map((page) => (
                    <NavLink
                        to={page.url}
                        key={page.id}
                        className={({ isActive }) => isActive ? `${menuStyle.menuButton} ${menuStyle.active}` : menuStyle.menuButton}
                    >
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <Box sx={{width: '1.5rem'}}>
                                <img src={page.img} style={{width: '100%'}} alt={'icon'}/>
                            </Box>
                            <Typography textTransform={'uppercase'} fontSize={14}>
                                {page.title}
                            </Typography>
                        </Stack>
                    </NavLink>
                ))}
            </Stack>
        </Box>
    );


    return (
        <Box sx={{flexGrow: 1, pb: 10}}>
            {matches && <Drawer
                variant="temporary"
                open={open}
                anchor={'right'}
                onClose={() => setOpen(!open)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block', lg: 'none'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 240},
                }}
            >
                {drawer}
            </Drawer>}
            <AppBar position={'fixed'} sx={{background: 'white', dropShadow: 'none', borderBottom: '2px solid #e3007d'}}
                    elevation={0}>
                <Toolbar>
                    <Stack justifyContent={'space-between'} direction={'row'} sx={{width: '100%', py: 2}}
                           alignItems={'center'}>
                        <Box component={Link} to={'/'}>
                            <Box sx={{width: '6rem'}}>
                                <img style={{width: '100%'}} src={edenredLogo}
                                     alt={'logo'}/>
                            </Box>
                        </Box>
                        <Box sx={{position: 'relative', flexGrow: 1}}>
                            <Slide direction={'left'} in={open} mountOnEnter unmountOnExit
                                   sx={{
                                       position: 'absolute',
                                       right: '0',
                                       width: '100%',
                                       height: '100%',
                                       display: {xs: 'none', lg: 'flex'},
                                   }}>
                                <Stack spacing={2.5} direction={'row'} justifyContent={'end'}
                                       sx={{width: '100%', height: '100%'}} alignItems={'center'}>
                                    {pages.map((page) => (
                                        <NavLink
                                            to={page.url}
                                            key={page.id}
                                            className={({ isActive }) => isActive ? `${menuStyle.menuButton} ${menuStyle.active}` : menuStyle.menuButton}
                                        >
                                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                <Box sx={{width: '1.5rem'}}>
                                                    <img src={page.img} style={{width: '100%'}} alt={'icon'}/>
                                                </Box>
                                                <Typography textTransform={'uppercase'} fontSize={13}>
                                                    {page.title}
                                                </Typography>
                                            </Stack>
                                        </NavLink>
                                    ))}
                                </Stack>
                            </Slide>
                            <Slide in={!open} direction={"left"} appear={false}
                                   sx={{position: 'absolute', right: '0', width: '100%', height: '100%'}}>
                                <Stack direction={'row'} justifyContent={'end'} sx={{width: '100%', height: '100%'}}
                                       alignItems={'center'}>
                                    <Box
                                        sx={{display:'flex', flexDirection:'row', justifyContent:'flex-end', marginRight:'4%', alignItems:'center'}}
                                    >
                                        <img src={newsIcon} style={{width: '25%', height:'25%' }} onClick={() => navigateNews()}/>
                                        <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={24}
                                                    sx={{
                                                        color: '#e31e13',
                                                        '&:hover': {color: '#ee7771'},
                                                        cursor: 'pointer',
                                                        marginLeft:'10px'
                                                    }}
                                                    onClick={() => navigateNews()}
                                        >
                                            NEWS
                                        </Typography>
                                    </Box>

                                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={24}
                                                onClick={() => setOpen(!open)}
                                                sx={{
                                                    color: '#e3007d',
                                                    '&:hover': {color: '#f55eb1'},
                                                    cursor: 'pointer'
                                                }}>
                                        Menu
                                    </Typography>

                                </Stack>
                            </Slide>
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Box>

    )
}

export default Menu;
