export const homeMap = [
    {
        sections:[
            {
                title:"Sorrento: terra delle sirene e dei miti",
                subtitle:"",
                description: <>Sorrento non è solo una destinazione, è <span
                    style={{color: '#e3007d'}}>un viaggio nella mitologia</span>. Secondo le
                    leggende greche, <span style={{color: '#e3007d'}}>queste coste ospitavano le</span><span style={{color: '#e3007d', fontWeight: 'bold'}}> sirene</span>, creature incantevoli che ammaliavano i marinai con il loro canto. Persino <span style={{color: '#e3007d'}}>Ulisse</span>, il leggendario eroe dell'Odissea, navigò vicino a queste terre magiche.</>,
                    image:"home-img-1"
            },
            {
                title:"Limoncello: il nettare di Sorrento",
                subtitle:"",
                description:<>Un <span style={{color: '#e3007d'}}>elisir ottenuto dai limoni unici della regione</span>. Questi limoni, grandi e succosi, sono <span style={{color: '#e3007d'}}>coltivati nei rigogliosi giardini terrazzati</span> che abbracciano le colline di Sorrento. Ogni sorso di limoncello racconta una storia di tradizione, luce e passione. Non dimenticare di assaggiarlo durante il tuo soggiorno!
                </>,
                image:"home-img-2"
            },
            {
                title:"Strade e panorami da cartolina",
                subtitle:"",
                description:<><span style={{color: '#e3007d'}}>Ogni angolo di Sorrento offre un panorama che lascia senza fiato</span>. Le <span style={{color: '#e3007d'}}>scogliere a picco sul mare</span> regalano una vista spettacolare sul Golfo di Napoli, con il Vesuvio maestoso all'orizzonte. Le <span style={{color: '#e3007d'}}>acque cristalline</span> scintillano al sole, invitandoti a immergerti in un mondo di bellezza naturale senza pari. I vicoli della città sono il posto perfetto per catturare <span style={{color: '#e3007d'}}>foto indimenticabili</span> e creare <span style={{color: '#e3007d'}}>ricordi preziosi</span>.
                </>,
                image:"home-img-3"
            },
            {
                title:"Artigianato: un tesoro di tradizioni\n",
                subtitle:"",
                description:<>Esplora le botteghe artigiane di Sorrento e scoprirai un mondo di creatività e maestria. La città è famosa per la sua tradizione della <span style={{color: '#e3007d'}}>lavorazione del legno intarsiato</span>, dove <span style={{color: '#e3007d'}}>abili artigiani creano opere d'arte uniche</span> utilizzando <span style={{color: '#e3007d'}}>tecniche tramandate da generazioni</span>. Ogni pezzo è un capolavoro che racconta la storia e l'anima di Sorrento.
                </>,
                image:"home-img-4"
            },
            {
                title:"Piedigrotta d’a mare: la festa più attesa di Napoli\n",
                subtitle:"",
                description:
                    <>
                        Un evento che <span style={{color: '#e3007d'}}>celebra la ritualità e l'identità di una città orgogliosa delle sue radici</span>: quasi ogni anno coinvolge cittadini, turisti e appassionati d'arte in una celebrazione che unisce culto sacro e tradizioni profane in luoghi emblematici, come il <span style={{color: '#e3007d'}}>santuario di Santa Maria di Piedigrotta e il Teatro di San Carlo</span>.<span style={{color: '#e3007d'}}> Allestimenti luminosi, carri scenografici, mostre fotografiche, laboratori musicali, balli tipici e spettacoli pirotecnici</span> creano un legame tra passato e presente, unendo turismo, cultura e religione in una <span style={{color: '#e3007d'}}>tradizione vivace e innovativa</span>.
                    </>,
                image:"home-img-5"
            }]
    },

                    /*{
                        title:"TRADIZIONI e FOLKLORE",
                        sections:[
                    {
                        title:"IL SIRTAKI",
                        description:<>Il sirtaki è una danza popolare greca. Si tratta in realtà di una <b>creazione originale del compositore Mikīs Theodōrakīs scritta appositamente per il film Zorba il greco nel 1964</b> e che viene perciò anche chiamata “danza di Zorba”. Caratteristica principale della danza e della musica d'accompagnamento è il fatto che il ritmo, da principio lento e sincopato, vada via via accelerando.</>,
                        image:"home-sirtaki"
                    },
                    {
                        title:"SUPERSTIZIONI GRECHE",
                        description:<>La tradizione vuole che per gelosia o invidia, attraverso falsi complimenti, sia possibile ricevere il malocchio. Per evitarlo viene indossata una piccola perla blu con un occhio bianco dipinto al centro. <b>Per questo prende il nome di “occhio greco” o “nazar” per i turchi</b> e ad oggi è anche una tipica decorazione da interni.
                        <br/>
                        Perché il colore è il blu? Nei paesi dell’Egeo gli occhi azzurri erano molto rari e iniziò a diffondersi la credenza che fossero portatori di malocchio. Il suo colore blu serve quindi a esorcizzare l’effetto del male.
                        <br/>
                        <b>Attenzione! L’usanza vuole che l’occhio greco non vada acquistato da sé, si accetta solo se regalato!
                        <br/>Inoltre, qualora si rompesse va immediatamente sostituito!</b>
                        </>,
                        image:"home-superstizioni-greche"
                    }
                        ]
                    },
                    {
                        title:"GASTRONOMIA GRECA",
                        sections:[
                    {
                        title:"L’ECCELLENZA OLTRETEMPO",
                        description: <>La cucina greca ha una storia millenaria che dai tempi di Alessandro Magno in poi, divenne una vera e propria arte.
                        <br/>
                        È in Grecia che sono apparsi i primi cuochi di mestiere, generosamente retribuiti dai ricchi per i quali preparavano lauti banchetti.<br/>
                        <b>Negli anni, dopo la conquista di Costantinopoli da parte dei Turchi, la cucina ellenica ha subito l’influenza di quella ottomana. Basti pensare ad alcuni tradizionali piatti greci: tzatziki, souvlaki e mussaka e tanti altri.</b>
                        </>,
                        image:"home-eccellenza"
                    },
                    {
                        title:"PÁME GIA KAFÉS?",
                        description: <>Non solo gli italiani amano il caffè!
                        <br/>
                        <b>In Grecia bere un caffè è un rito</b> così abituale che l'invito <i>“páme gia kafés?”</i> (andiamo a prendere un caffè?) è ormai più comune del classico “usciamo”.
                        <br/>
                        Si tratta di un caffè bollito che risulta molto più diluito dell'espresso italiano, più simile a quello americano ma con un gusto molto più intenso.
                        </>,
                        image: "home-kafes"
                    }
                        ]
                    },
                    {
                        title:"CURIOSITÀ",
                        sections:[
                    {
                        title:"L’UCCELLO DEL BUON AUGURIO",
                        description: <>Il <b>pellicano</b> è un simbolo importante di Mykonos.
                        <br/>
                        A metà degli anni ’50 una di queste creature fu ritrovata sull’isola da un pescatore che si prese cura delle sue ferite affinché potesse riprendere il suo viaggio migratorio verso il Nilo. Il pellicano, a cui venne dato il nome di <b>Petros</b>, una volta guarito, decise di non andarsene più, diventando un amico per gli abitanti del posto e un’attrazione per i viaggiatori di passaggio.
                        <br/>
                        <b>Venne considerato un simbolo di fortuna perché dopo il suo arrivo l'attività turistica aumentò notevolmente</b>, portando tanti visitatori.
                        </>,
                        image:"home-uccello-buon-augurio"
                    }
                        ]
                    }*/
                    ]
