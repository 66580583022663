import React from "react";
import {Box, Stack, Typography} from "@mui/material";

export const PageTitle = ({title, image}) => {
    return(
        <Stack direction={'row'} alignItems={'top'} spacing={2}>
            <Box sx={{width:'2rem'}}>
                <img style={{width:'100%'}} src={require(`../../assets/images/${image}.png`)}/>
            </Box>
            <Box>
                <Typography textTransform={'uppercase'} variant={'h5'} fontWeight={'bold'} color={'#e63023'}>
                    {title}
                </Typography>
                <Box sx={{py:2}}>
                    <Box sx={{borderBottom:'3px solid #e63023', width:'4rem'}}/>
                </Box>
            </Box>
        </Stack>
    )
}
